.parentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.webcamDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 842px;
}

.faceShape {
  position: absolute;
  height: 100%;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70%;
  margin: 20px;
  padding: 30px;
}
.backBtn {
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  position: absolute;
  left: 0px;
  bottom: -10px;
}
.showDevicesBtn {
  margin: auto;
  padding: 15px;
  border: 2px solid black;
  color: black;
  background: transparent;
}
.showDevicesBtn:hover {
  background: black;
  color: white;
}
.deviceBtn {
  padding: 10px;
  background: transparent;
  border: 2px solid #dddddd;
  margin: 10px auto;
}
.note {
  font-weight: 300;
  color: #212529;
  font-size: 14px;
}
.shutterWrapper {
  width: 70%;
  margin: 20px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.makeWidthHalf {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.click {
  background: transparent;
  border: 1px solid black;
  border-radius: 50%;
  outline: none;
}
.smallText {
  font-weight: 300;
  font-size: 10px;
}
.flipCamera {
  background: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;
}
@media all and (max-width: 800px) {
  .webcamDiv {
    position: relative;

    width: 100%;
  }
  .camera-video-box {
    width: 100%;
    height: 100%;
    margin: 12px 0;
  }
  .header {
    position: relative;

    width: 100%;
  }
  .makeWidthHalf {
    width: 60%;
  }
  .shutterWrapper {
    width: 100%;
  }
}
