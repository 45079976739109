.navbar-toggler{
    border: none;
    outline: none;
}
.navbar-toggler-icon{
    background-image: url('./menu.png');
}

.navbar-toggler:focus{
    border: none;
    outline: none;
    box-shadow: none !important;
}