.container {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    margin: 20px auto;
  }
  .modelImg {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
  }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hairstyleImg {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .heading {
    text-transform: uppercase;
    padding: 15px;
    border: 1px solid black;
    font-size: 18px;
    text-align: center;
    width: fit-content;
    margin: 20px auto;
  }
  .commonBtnstyles {
    border: 1px solid #616161;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #000;
    margin: auto;
    /* width: 95px; */
    padding: 10px;
  }
  .btnText {
    font-size: 10px;
    font-weight: 500;
    margin: 5px auto;
  }
  @media all and (max-width: 800px) {
    .container {
      grid-template-columns: repeat(2, 1fr);
    }
    .modelImg {
      position: relative;
      width: 200px;
      height: 200px;
    }
  }
  