.btnWrapper{
    width: 70%;
    display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  justify-items: center;
  position: relative;
}

.commonBtnStyles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: transparent;
    outline: none;
    border: 3px solid #e9ecef;
    color: black;
    font-weight: 500;
    padding: 10px;
  }

  .btnText{
    font-weight: 500;
    font-size: 10px;
    margin: 10px auto;
    
  }
  .btnIcon{
    font-weight: 500;
    font-size: 40px;

  }

  @media all and (max-width:700px){
    .btnWrapper{
        width: 100%;
    }
    .commonBtnStyles{
        padding: 5px;
    }
  }