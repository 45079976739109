.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
  position: relative;
  margin: 20px auto;
}
.modelImg {
  position: relative;
  width: 200px;
  height: 200px;
  cursor: pointer;
}
.hairstyleImg {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.heading {
  text-transform: uppercase;
  padding: 15px;
  border: 1px solid black;
  font-size: 18px;
  text-align: center;
  width: fit-content;
  margin: 20px auto;
}

@media all and (max-width: 800px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
  .modelImg {
    position: relative;
    width: 200px;
    height: 200px;
  }
}
