.parentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading {
  font-weight: 400;
  font-size: 16px;
  margin: 20px auto;
  text-align: center;
}
.commonBtnstyles {
  border: 1px solid #616161;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #000;
  margin: auto;
  /* width: 95px; */
  padding: 10px;
}
.girlImage{
  width: 540px;
  margin: 20px auto;
}
.btnText {
  font-size: 10px;
  font-weight: 500;
  margin: 5px auto;
}
.btnWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px auto;
}
.guidelinesBox{
    background: #e9ecef;
    width: 70%;
    padding: 10px;
    margin: 20px auto;
}
.guidelinesHeading{
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
}
.list{
    margin:0 10px 10px 10px;
    width: 100%;
  
    font-size: 14px;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 300;
    text-align: start;
    background-color: #E9ECEF;
    color:black;
    list-style-type: "✱ "; 
}
.list li {
    margin: 10px 0;
}
@media all and (max-width: 800px) {
  .btnWrapper {
    width: 100%;
}
  .guidelinesBox{
      width: 90%;
      
  }
  .girlImage{
    width: 100%;
    
  }
}
