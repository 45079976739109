.styleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}
.heroImg {
  position: relative;
  width: 640px;
  height: 640px;
}
.modelAndHairstyleImg {
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.headerDetails {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
}
.hairstyleNo {
  position: absolute;
  left: 20px;
  bottom: -10px;
  font-weight: 300;
  font-size: 20px;
}
.linkToPackageHairstyles {
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.CTAbtn {
  background: #3ab40e;
  padding: 10px;
  margin: 20px auto;
  color: white;
  text-transform: uppercase;
  border: none;
  outline: none;
  border-radius: 5px;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
@media all and (max-width: 700px) {
  .heroImg {
    position: relative;
    width: 350px;
    height: 350px;
  }
  .CTAbtn {
    width: 90%;
  }
  .headerDetails {
    width: 100%;
  }
  .hairstyleNo {
    bottom: -30px;
  }
}
