.sliderParent {
  height: 130px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #c7c7c7;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnArrow {
  background: #f0f0f0;
  height: 100%;
  font-size: 15px;
  border: none;
  outline: none;
}

.colorImg {
  position: relative;
  width: 110px;
  height: 120px;
}
.colorImgActive {
  position: relative;
  width: 110px;
  height: 120px;
  border:1px solid green
}

@media all and (max-width: 800px) {
  .colorImg {
    width: 70px;
    height: 80px;
  }
  .colorImgActive {
    width: 70px;
    height: 80px;
  }
}
