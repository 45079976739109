.buttonWrapper {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
}

.commonBtnStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  outline: none;
  border: none;
  color: black;
  font-weight: 500;
}

.btnText {
  font-weight: 500;
  font-size: 10px;
  padding: 10px;
}
