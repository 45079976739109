.smallHairstyleInfoDescription {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
  margin: 10px 0;
}
.myStyleTableInfo {
  border: 1px solid #eef1f3;

  padding: 10px;
  text-align: left;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;

  color: #616161;
}

.mystyleTableTd {
  text-transform: capitalize;
  text-align: left;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;

  border: 1px solid #eef1f3;
  color: #000000;
}
.mystyleTable {
  border: 1px solid #eef1f3;
}
.hairstyleInfoName {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
