.packageWrapper {
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border :3px solid #e9ecef;
  margin-top: 20px;
}

.packageTitle {
  width: 200px;

  font-size: 20px;
  font-weight: 700;
  text-align: center;
  word-wrap: break-word;
}
.packageDescription {
  word-wrap: break-word;
  font-size: 17px;
  font-weight: 500;
  width: 200px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.previewNowBtnGreen {
  margin-bottom: 10px;
  padding: 10px;
  width: calc(100% - 20%);
  background: #58bb3a;
  text-transform: uppercase;
  color: white;
  border: none;
  outline: none;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.buyNowOrTryNowBtn {
  margin-bottom: 10px;
  padding: 10px;
  width: calc(100% - 20%);
  background: #7b61ff;
  text-transform: uppercase;
  color: white;
  border: none;
  outline: none;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  text-align: center;
}
.noOfHairstyles {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}
