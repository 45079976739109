.parentDiv{
    position: relative;
    width: 640px;
    height: 640px;
    border: 3px solid black;
}

@media all and (max-width:700px){
    .parentDiv{
        position: relative;
        width: 350px;
        height: 350px;
    }
}  