.headerWrapper{
    display:flex;
    justify-content: center;
    align-items: center;
    background: black;
    padding: 30px;
    height: 80px;
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    font-family: 'Sumana';
    font-size: 24px;
    font-weight: 400;
}
