
.brandImage{
    display: flex;
    justify-content: center;
    align-items: center;
    background:black;
    padding: 30px;
    
}
.sidebarWithChildren{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height:700px;
}

.content{
   flex:1;
    height: 100%;
}
.navItem{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px auto;
    cursor: pointer;
}
.navItem:hover{
    background: #E9ECEF ;
    border-left: 3px solid black;
}
.selectedNavItem{
    background: #E9ECEF ;
    border-left: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px auto;
    cursor: pointer;

}
.navItemText{
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    padding-left:10px;
    font-family: 'Readex Pro';
    font-weight: 500;
}
.navItemIcon{
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}
.parentWrapperOfSideBar{
    width: 20%;
    height: 100%;
    background: white;
    min-height:700px;

}

@media all and (max-width:800px){
    .parentWrapperOfSideBar{
        display:none;
    }
}