.parentWrapper{
    width: 100%;
    background: #f8f9fa;
    min-height:700px;
    
}
.navbarParent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0;
    border-bottom: 1px solid #000;
    height: 86px;
    background: white;
  }
  .navbarContentWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbarBrand {
    padding-left: 60px;
  }
  
  .navbarMenuLinks {
    padding-right: 60px;
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: -30px;
  }
  
  .linksBox {
    width: fit-content;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .link {
    padding: 0 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    font-size: 12px;
    font-family: 'Readex Pro', sans-serif;
  }
  
  .link:hover {
    background: black;
    color: #7b61ff !important;
  }
  
  .Selected{
    background: black;
    color: white !important;
    padding: 0 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
   
    font-size: 12px;
    font-family: 'Readex Pro', sans-serif;
  }

  .footer{
    
    width: 100%;
    background: black;
}

.parent{
    width: calc(100% - 10%);
    margin: auto;
    background: black;
    display: flex;
    justify-content: space-between;
}    

.firstBox{
    width: 50%;
    padding: 40px 35px 32px 15px;
}
.secondBox{
    width: 25%;
    padding: 40px 15px 32px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.thirdBox{
    width: 25%;   
    padding: 40px 15px 32px;

}

.headingFooter{
    font-family: 'Readex Pro', sans-serif;
    font-weight: 500;
    font-size:24px !important;
    text-transform: uppercase  !important;
    margin-bottom: 15px;
    color: white;
}
.headingDescription{
    margin-bottom: 15px;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 300 !important;
    font-size:14px !important;
    line-height: 1.5;
    color: white;

}

.headingFooterSecondBox{
    margin-bottom: 15px;
    margin-top: 15px;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 500 !important;
    font-size:14px !important;
    line-height: 1.5;
    color: white;
    text-transform: uppercase;
}

.smallLinks{
    font-family: 'Readex Pro', sans-serif;
    font-weight: 300 !important;
    font-size:14px !important;
    line-height: 1.5;
    color: white;
    text-transform: capitalize;
    text-decoration: none;
}
.smallLinks:hover{
    color: #7b61ff;
}

.belowMainFooter{
    padding: 40px 0px 20px;
    width: calc(100% - 10%);
    margin: auto;
    display: flex;

}

.belowFooterLinks{
    font-family: 'Readex Pro', sans-serif;
    font-weight: 300 !important;
    font-size:14px !important;
    line-height: 1.5;
    color: white;
    text-transform: capitalize;
    padding: 0px 14px 8px 0;
}

.belowFooterLinks:hover{
    color: #7b61ff;
}

.bottomMostTitle{
    padding: 20px 15px;
    width: calc(100% - 10%);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleDate{
    color: white;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 300 !important;
    font-size:14px !important;
    display: flex;
}



@media all and (max-width:800px){
    .footer{
        margin-top: 20px;
    }
    .parent{
        flex-direction: column;
    }

    .firstBox{
        width: 100%;
    }
    .secondBox{
        width: 100%;
    }
    
    .thirdBox{
        width: 100%;
    
    }
    .bottomMostTitle{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .titleDate{
        padding: 8px 0;
    }
}

@media all and (max-width:800px){
    .navbarParent{
        display:none;
    }
}