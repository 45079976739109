.Parent{
    height: fit-content;
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    width: 100%;
    position: sticky;
    z-index: 9999;
    bottom: 0;
    border: 2px solid black;
}
.BottomNavItem{
    width: 100%;
    max-width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background: black;
    color: white;
}
.BottomNavItemSelected{
    width: 100%;
    max-width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background: white;
    color: black;
    }
.bottomNavItemText{
    font-size: 10px;
    font-weight: 300;
    font-family: 'Readex Pro';
    text-align: center;
}